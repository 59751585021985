<script setup>
import {RouterLink, RouterView} from 'vue-router'
import Header from './components/Header.vue'
import {useAuthStore} from "./stores/AuthStore";
const authStore = useAuthStore();
const headerSubtitle = ref(0);

provide("headerSubtitle", headerSubtitle);
// return { authStore }

</script>

<script>
import {GrowBottomNavigation} from "bottom-navigation-vue";
import "bottom-navigation-vue/dist/style.css";
import "./assets/fontawesome/css/fontawesome.min.css"
import "./assets/fontawesome/css/brands.min.css"
import "./assets/fontawesome/css/solid.min.css"
import { provide, ref } from "vue";
import {useAuthStore} from './stores/AuthStore'

// https://vue-bottom-navigation.netlify.app/navigations/grow
export default {
    components: {GrowBottomNavigation},
    data: () => ({
        car: 12,
        selected: 1,
        options: [
            {id: 1, icon: 'fa-solid fa-home', title: 'Home', color: '#5639af', path: {name: "home"}},
            {
                id: 2,
                icon: 'fa-solid fa-trowel-bricks',
                title: 'Assembleren ',
                color: '#ac4793',
                path: {name: "assemble"}
            },
            {id: 3, icon: 'fa-solid fa-pallet', title: 'Stapelen ', color: '#e2a93a', path: {name: "stack"}},
            {
                id: 4,
                icon: 'fa-solid fa-arrow-right-from-bracket',
                title: 'Logout',
                color: '#4493a7',
                path: {name: "logout"}
            },
            {id: 4, icon: 'fa-solid fa-arrow-right-to-bracket', title: 'Login', color: '#4493a7', path: {name: "login"}}
        ]
    }),
};
</script>


<template>
    <div class="grid-container">
        <Header :car="car"/>
        <RouterView/>
    </div>


    <GrowBottomNavigation :options="options" v-model="selected">
        <template #icon="{ props }"><i :class="`${props.icon}`"/>{{ props.title }}</template>
    </GrowBottomNavigation>
</template>
<style>
.gr-btn-container .gr-btn-icon, .gr-btn-container .gr-btn-item {
    overflow: initial;
    font-size: 18px;
    width: auto;
}

.gr-btn-container .gr-btn-title {
    display: none;
}
</style>

<style scoped>


.gr-btn-container .gr-btn-icon, .gr-btn-container .gr-btn-item {
    overflow: initial;
    font-size: 18px;
    width: auto;
}

.gr-btn-container .gr-btn-title {
    display: none;
}

header {
    line-height: 1.5;
    max-height: 100vh;
}


@media (min-width: 1024px) {


    .logo {
        margin: 0 2rem 0 0;
    }

}
</style>
