export class ExactorderModel {
    constructor(ordernr, line) {
        this.ordernr = ordernr
        this.line = line
        this.fields = null
        this.state = null
        this.status = null
    }

    setProperties(status, fields) {
        this.status = status
        this.fields = fields
    }

    setState(state) {
        this.state = state
    }


    get order() {
        return this.ordernr + "-" + this.line
    }
}


// state should be a model also:
// int $total;
// int $done;
// int $todo;
// int $currentpallet;
// int $palletstotal;
// int $currentpalletsize;
// bool $palletfull;
// bool $orderdone;

