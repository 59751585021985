import { defineStore } from "pinia";
import axios from 'axios';
const API_URL = '/auth';
export const useAuthStore = defineStore("user", {
    state: () => ({
        user: null,
        car : null
    }),
    persist: true,
    getters: {
        isLoggedIn(state) {
            return ( state.user !== null )
        },
        token(state) {
            if ( state.user ) {
                return state.user.token;
            }
            return null;
        }
    },
    actions: {
        async login(form) {
            const response = await axios.post(API_URL, {
                email: form.username,
                password: form.password
            })
            this.user = null
            if ( response.data.token ) {
                let token = response.data.token
                const response2 = await axios.get("/api/users?page=1&email="+encodeURIComponent(form.username),
                    { headers: "Authorization: Bearer " + token})
                this.user = response2.data['hydra:member'][0]
                this.user.token = token
                this.car = form.car
            }
        },
        async check() {
            try {
                const response = await axios.post('/auth/check', {
                    token: this.token
                })
                return response.status === 200
            } catch (error) {
                return false
            }
        },
        logout() {
            this.user = null
        }
    },
});
