<script setup>
import {useAuthStore} from "../stores/AuthStore";
import {useExactStore} from "../stores/ExactStore";
import { inject, ref } from "vue";
defineProps({
    car: {
        type: Number,
        required: true
    }
})
const authStore = useAuthStore();

const headerSubtitle = inject("headerSubtitle", ref(-1));

</script>
<script>

import {useAuthStore} from "../stores/AuthStore";
import {useExactStore} from "../stores/ExactStore";

export default {
    data() {
        return {
            c: 0,
        };
    },
    // setup() {
    //     return {headerSubtitle};
    // },
};
</script>

<template>
    <div class="item1l">
        <img alt="Lightwell logo" class="logo" src="@/assets/logo.jpg" width="125" height="125"/>
    </div>
    <div class="item1">
        <h3>
            FLV app - kar {{ authStore.car }}
        </h3>
        <h2>{{headerSubtitle}}</h2>
    </div>
</template>

<style>
.item1l, .item1 {
    background-color: lightgoldenrodyellow;
}

h3 {
    /*font-size: 30px;*/
}
</style>

