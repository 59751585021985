import { createApp } from 'vue'
import { createPinia } from 'pinia'

// import ToastPlugin from 'vue-toast-notification';
// // Import one of the available themes
// import 'vue-toast-notification/dist/theme-default.css';
// import 'vue-toast-notification/dist/theme-bootstrap.css';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

import App from './App.vue'
import router from './router'

import bottomNavigationVue from "bottom-navigation-vue";
import "bottom-navigation-vue/dist/style.css";

import {plugin, defaultConfig} from '@formkit/vue'
import piniaPluginPersistedState from "pinia-plugin-persistedstate"

import io from 'socket.io-client';

import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* import specific icons */
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { faPrint } from '@fortawesome/free-solid-svg-icons'
import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons'
library.add(faTrash)
library.add(faPrint)
library.add(faArrowUp)
library.add(faArrowDown)

import './assets/main.css'

defaultConfig({  theme: 'genesis'})

const app = createApp(App)
const pinia = createPinia();
pinia.use(piniaPluginPersistedState)

// app.directive('focus', {
//     // When the bound element is mounted into the DOM...
//     mounted(el) {
//         // Focus the element
//         el.focus()
//     }
// })

app.component('font-awesome-icon', FontAwesomeIcon)
// https://d8devs.com/vue3-global-socket-io-installation-in-all-components/
// app.config.globalProperties.$socketio = io('http://localhost:8080');
app.config.globalProperties.$socketio = io();
app.use(bottomNavigationVue);
app.use(plugin, defaultConfig)
// app.use(ToastPlugin);

const options = {
    // You can set your default options here
};
app.use(Toast, options);

app.use(pinia)
app.use(LoadingPlugin);
app.use(router)
app.mount('#app')
